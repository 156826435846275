import { inject, Injectable, DestroyRef } from '@angular/core'
import { Messaging, getToken, onMessage, deleteToken } from '@angular/fire/messaging'
import { Observable, BehaviorSubject, catchError, EMPTY, Subject, takeUntil, firstValueFrom } from 'rxjs'
import { isIOSSafari, isSafari, log } from '@nx-superprep/utils'
import { ConsentService } from './consent.service'
import { environment } from '@nx-superprep/environment'

import { Firestore, doc, setDoc } from '@angular/fire/firestore'
import { Auth, user } from '@angular/fire/auth' // Assuming Firebase Auth is used
import { MatSnackBar } from '@angular/material/snack-bar'
import { SnackbarComponent } from '../components'

export interface FcmMessage {
  notification?: {
    title?: string
    body?: string
  }
  data?: Record<string, string>
}

export interface FcmStatus {
  isEnabled: boolean
  token: string | null
  permission: NotificationPermission
}

@Injectable({ providedIn: 'root' })
export class FcmService {
  private readonly firestore = inject(Firestore);
  private readonly auth = inject(Auth);
  private readonly messaging = inject(Messaging)
  private readonly consentService = inject(ConsentService)
  private readonly destroyRef = inject(DestroyRef)
  private readonly destroy$ = new Subject<void>()
  private readonly snackBar = inject(MatSnackBar)

  private readonly status = new BehaviorSubject<FcmStatus>({
    isEnabled: false,
    token: null,
    permission: 'default'
  })

  readonly status$ = this.status.asObservable()

  constructor() {
    // Initialize FCM if consent is already granted
    if (this.consentService.getConsent()) {
      this.initializeFcm().catch(err =>
        log.error('🔴FCM failed to initialize during construction:', err)
      )
    }

    this.listenForMessages()

    // Cleanup on destroy
    this.destroyRef.onDestroy(() => {
      this.destroy$.next()
      this.destroy$.complete()
      this.status.complete()
    })
  }


  /**
   * Listens for incoming FCM messages when the app is in the foreground.
   */
  private listenForMessages(): void {
    if (isIOSSafari() || isSafari()) {
      log.warn('⚠️ Foreground push notifications are not supported on iOS Chrome/Safari.')
      return
    }

    try {
      onMessage(this.messaging, (payload) => {
        log.debug('📩 Foreground push received:', payload)

        const { title, body } = payload.notification || {}
        const imageUrl = payload.notification?.image || '/assets/icons/icon-192x192.png'

        // Show the snackbar notification
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: {
            type: 'cover',
            title: title || 'New Message',
            subTitle: body || '',
            imageUrl: imageUrl
          },
          duration: 5000,
          panelClass: ['custom-snackbar']
        })
      })
    } catch (error) {
      log.error('🔴 Error initializing onMessage listener:', error)
    }
  }

  /**
   * Initializes the Firebase Cloud Messaging service.
   * @returns Promise that resolves when initialization is complete
   * @throws Error if initialization fails
   */
  private async initializeFcm(): Promise<void> {
    try {
      const swRegistration = await Promise.race([
        navigator.serviceWorker.ready,
        new Promise<ServiceWorkerRegistration>((_, reject) =>
          setTimeout(() => reject(new Error('Service worker took too long to be ready')), 5000)
        )
      ])

      let token = this.status.getValue().token
      if (!token) {
        token = await getToken(this.messaging, {
          vapidKey: environment.config.firebase.vapidKey,
          serviceWorkerRegistration: swRegistration
        });

        if (token) {
          await this.saveTokenToDatabase(token);
        }
      }

      this.updateStatus({ isEnabled: true, token, permission: Notification.permission })

      log.debug('🟢FCM service worker successfully enabled')
    } catch (err) {
      this.updateStatus({ isEnabled: false })
      throw new Error('🔴FCM service worker failed to initialize: ' + (err instanceof Error ? err.message : String(err)))
    }
  }

  /**
   * Requests notification permission from the user and initializes FCM if granted.
   * @returns Promise that resolves with the permission status
   */
  async requestPermission(): Promise<NotificationPermission> {
    try {
      const permission = await Notification.requestPermission()

      if (permission === 'granted') {
        this.consentService.setConsent(true)
        await this.initializeFcm()
      } else {
        this.consentService.setConsent(false)
        this.updateStatus({ permission })
        log.warn(`Notification permission ${permission}`)
      }

      return permission
    } catch (err) {
      log.error('Failed to request notification permission:', err)
      throw new Error('Failed to request notification permission: ' + (err instanceof Error ? err.message : String(err)))
    }
  }

  /**
   * Listens for incoming FCM messages.
   * @returns Observable of FCM messages
   */
  getMessages(): Observable<FcmMessage> {
    if (!this.status.getValue().isEnabled) {
      log.warn('Attempting to get messages while FCM is disabled')
      return EMPTY
    }
    log.debug('🟢Listening for FCM messages...')
    return new Observable<FcmMessage>(subscriber =>
      onMessage(this.messaging, message => {
        log.debug('📩Received FCM message:', message)
        subscriber.next(message as FcmMessage)
      })
    ).pipe(
      takeUntil(this.destroy$),
      catchError(err => {
        log.error('🔴Error receiving FCM message:', err)
        return EMPTY
      })
    )
  }

  /**
   * Deletes the current FCM token and removes it from the database.
   * @returns Promise that resolves when the token is deleted
   */
  async deleteFcmToken(): Promise<void> {
    try {
      await deleteToken(this.messaging)
      await this.removeTokenFromDatabase()
      this.updateStatus({ token: null })
      log.debug('FCM token deleted successfully')
    } catch (err) {
      throw new Error('Failed to delete FCM token: ' + (err instanceof Error ? err.message : String(err)))
    }
  }

  /**
   * Updates the FCM status and notifies subscribers.
   * @param partial - Partial status update
   */
  private updateStatus(partial: Partial<FcmStatus>): void {
    this.status.next({
      ...this.status.getValue(),
      ...partial
    })
  }

  private readonly authUser$ = user(this.auth)

  /**
   * Saves the FCM token to the database for the current user.
   * @param token - The FCM token to save
   */
  private async saveTokenToDatabase(token: string): Promise<void> {
    const currentUser = await firstValueFrom(this.authUser$)
    if (!currentUser) {
      log.warn('Cannot save FCM token - No authenticated user')
      return
    }

    const userRef = doc(this.firestore, `users/${currentUser.uid}`)
    try {
      await setDoc(userRef, { fcmToken: token }, { merge: true })
      log.debug('FCM token saved successfully:', token)
    } catch (err) {
      log.error('Failed to save FCM token:', err)
    }
  }

  /**
   * Removes the FCM token from the database for the current user.
   */
  private async removeTokenFromDatabase(): Promise<void> {
    const currentUser = await firstValueFrom(this.authUser$)
    if (!currentUser) {
      log.warn('Cannot remove FCM token - No authenticated user')
      return
    }

    const userRef = doc(this.firestore, `users/${currentUser.uid}`)
    try {
      await setDoc(userRef, { fcmToken: null }, { merge: true })
      log.debug('FCM token removed successfully')
    } catch (err) {
      log.error('Failed to remove FCM token:', err)
    }
  }
}
