import { AsyncPipe, NgTemplateOutlet } from '@angular/common';
import { Component, Inject } from '@angular/core'
import { MatCardModule } from '@angular/material/card'
import { MatIconModule } from '@angular/material/icon'
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar'
import { FirebaseError } from 'firebase/app'
import { from } from 'rxjs'

@Component({
  standalone: true,
  selector: 'nx-superprep-snackbar',
  templateUrl: './snackbar.component.html',
  styleUrls: ['./snackbar.component.scss'],
  imports: [AsyncPipe, NgTemplateOutlet, MatCardModule, MatIconModule],
})
export class SnackbarComponent {
  constructor(
    public snackBarRef: MatSnackBarRef<SnackbarComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data: {
      type: string,
      title: string,
      subTitle: string,
      imageUrl: string,
      error?: FirebaseError
    }
  ) { }

  get type() {
    return this.data?.type ?? 'cover'
  }
  get title() {
    return this.data?.title
  }
  get subTitle() {
    return this.data?.subTitle
  }
  get imageUrl() {
    return from([this.data?.imageUrl])
  }
  get backgroundColor() {
    return (this.data?.error) ? 'red' : 'green'
  }

  dismiss() {
    this.snackBarRef.dismiss()
  }
}
