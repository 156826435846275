import { inject, Injectable } from "@angular/core"
import { HttpStatusCode } from "@angular/common/http"

import { Observable, catchError, distinctUntilChanged, map, of, switchMap } from "rxjs"

import { Functions, httpsCallableData } from "@angular/fire/functions"

import { isEqual } from "lodash-es"

import { log } from "@nx-superprep/utils"
import { BackendConfigService } from "@nx-superprep/backend/config"

import { ApplicationSetting, CardRetrievalRequest, ClassroomInformationResponse, ClassroomJoinRequest, DayPlanRequest, DayPlanResponse, FrontendCard, FunctionStatusResponse, Partner, PopApplication, StudyLibraryRequest, StudyLibraryResponse, StudySetting, User } from "../models"
import { UserService } from "./user.service"
import { DocumentData, DocumentReference, getDoc } from "@angular/fire/firestore"
import { AnalyticsService } from "./analytics.service"


@Injectable({ providedIn: 'root' })
export abstract class LongtailService extends AnalyticsService {
  readonly backendService = inject(BackendConfigService)
  readonly userService = inject(UserService)
  readonly functions = inject(Functions)

  protected studyGetLibrary: (data: StudyLibraryRequest) => Observable<StudyLibraryResponse>
  protected studyGetDayPlan: (data: DayPlanRequest) => Observable<DayPlanResponse>
  protected studyGetCards: (data: CardRetrievalRequest ) => Observable<FrontendCard[]>
  protected requestClassroomInfo: (data: ClassroomJoinRequest ) => Observable<ClassroomInformationResponse>
  protected requestClassroomJoin: (data: ClassroomJoinRequest ) => Observable<FunctionStatusResponse>

  constructor() {
    super()
    this.studyGetLibrary = httpsCallableData(this.functions, 'study_get_library_v1')
    this.studyGetDayPlan = httpsCallableData(this.functions, 'study_get_day_plan_v1')
    this.studyGetCards = httpsCallableData(this.functions, 'study_get_cards_v1')
    this.requestClassroomInfo = httpsCallableData(this.functions, 'request_public_classroom_information_v1')
    this.requestClassroomJoin = httpsCallableData(this.functions, 'request_classroom_join_v1')
    this.userService.userData.pipe(switchMap((user) => this.onUserChange(user))).subscribe()
  }

  abstract clear(): void

  override onUserChange(user: User | undefined): Observable<User | undefined> {
    if (this.clear) { this.clear() }
    super.onUserChange(user)
    return of(user as User | undefined)
  }

  lt_application = ['Staging', 'Production'].includes(this.backendService.firebase.environment??'') ? PopApplication.LT_PROD_WEB : PopApplication.LT_DEV_WEB

  get uid() {
    return this.userService.uid
  }

  // classroom

  validateClassroomCode(code: string)  {
    const data = {code, partner: Partner.POPTEAMS}
    return this.requestClassroomInfo(data)
    .pipe(
      catchError((error) => {
        const result = {statusCode: HttpStatusCode.NotFound, statusMessage: String(error.message)} as ClassroomInformationResponse
        return of(result)
      }),
      map(result => ({...result, statusCode: result.statusCode ?? HttpStatusCode.Ok }))
    )
  }

  joinClassroom(code: string) {
    const data = {code, partner: Partner.POPTEAMS}
    return this.requestClassroomJoin(data)
    .pipe(
      catchError((error) => {
        const result = {statusCode: HttpStatusCode.NotFound, statusMessage: String(error.message)} as ClassroomInformationResponse
        return of(result)
      }),
    )
  }

  // setting

  getAppSetting(uid = this.uid) {
    if (!uid) { log.debug(`no active user`); return of(new ApplicationSetting('', new StudySetting({}))) }
    return this.userService.getAppSetting(uid).pipe(distinctUntilChanged(isEqual))
  }

  setAppSetting(value: ApplicationSetting, uid = this.uid) {
    if (!uid) { return Promise.reject('no active user') }
    return this.userService.setAppSetting(value, uid)
  }

  // docs

  refExists(ref: DocumentReference<DocumentData, DocumentData>) {
    return getDoc(ref).then(s => s.exists()).catch(error => {log.debug(this.uid, error); return false})
  }

  // account removal
  removeAccount() {
    const uid = this.uid
    log.debug(`removing account ${uid}`)
    return uid ? this.userService.removeAccount().then(() => this.logAccountDeletion({uid})) : Promise.resolve(true)
  }
}
