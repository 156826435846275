import {
  ApplicationConfig, PLATFORM_ID, inject, isDevMode,
  provideAppInitializer,
  //provideExperimentalZonelessChangeDetection,
  provideZoneChangeDetection
} from '@angular/core'
import {
  provideRouter,
  //withDebugTracing,
  withEnabledBlockingInitialNavigation,
  withRouterConfig,
  withViewTransitions,
  Router,
  withComponentInputBinding,
  //withDebugTracing
} from '@angular/router'
import { appRoutes } from './app.routes'
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async'
import { importProvidersFrom, ErrorHandler } from '@angular/core'
import { provideClientHydration, withHttpTransferCacheOptions } from '@angular/platform-browser'
import { provideServiceWorker } from '@angular/service-worker'
import { provideHttpClient, withFetch } from '@angular/common/http'
import { isPlatformServer } from '@angular/common'
import { environment } from '@nx-superprep/environment'
import { log } from '@nx-superprep/utils'

import { NgCircleProgressModule } from 'ng-circle-progress'

import * as Sentry from '@sentry/angular'

import { getApp, initializeApp, provideFirebaseApp } from '@angular/fire/app'
import { Auth, browserLocalPersistence, browserPopupRedirectResolver, browserSessionPersistence, connectAuthEmulator, indexedDBLocalPersistence, initializeAuth, provideAuth } from '@angular/fire/auth'
import {
  connectFirestoreEmulator,
  initializeFirestore,
  persistentLocalCache,
  persistentMultipleTabManager,
  provideFirestore
} from '@angular/fire/firestore'
import {
  connectStorageEmulator,
  getStorage,
  provideStorage
} from '@angular/fire/storage'
import {
  connectFunctionsEmulator,
  getFunctions,
  provideFunctions
} from '@angular/fire/functions'
import { initializeAnalytics, provideAnalytics, ScreenTrackingService, setAnalyticsCollectionEnabled, setDefaultEventParameters, UserTrackingService } from '@angular/fire/analytics'
import { APP_VERSION, APP_NAME, DEBUG_MODE } from '@angular/fire/compat/analytics'

//import { setLogLevel } from '@angular/fire/app'
//import { getRemoteConfig, provideRemoteConfig } from '@angular/fire/remote-config'
import { getMessaging, provideMessaging } from '@angular/fire/messaging'
//import { ReCaptchaEnterpriseProvider, initializeAppCheck, provideAppCheck } from '@angular/fire/app-check'
//import { connectDatabaseEmulator, getDatabase, provideDatabase } from '@angular/fire/database'

function provideSentryProviders(platformId: object) {
  if (!environment.production || isPlatformServer(platformId)) {
    return []
  }
  return [
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        logErrors: true,
        showDialog: false
      })
    },
    {
      provide: Sentry.TraceService,
      deps: [Router]
    },
    provideAppInitializer(() => {
      inject(Sentry.TraceService)
    }),
  ]
}

function provideFirebaseProviders() {
  const productionProviders = (isDevMode() || environment.production) ? [
    provideAnalytics(() => {
      const analytics = initializeAnalytics(getApp(), {
        config:{app_name: environment.config.app_name, app_version:environment.config.version}
      })
      setAnalyticsCollectionEnabled(analytics, true)
      setDefaultEventParameters({app_name: environment.config.app_name, app_version:environment.config.version})
      return analytics
    }),
    {provide: APP_NAME, useValue: environment.config.name ?? 'Popstudy Web'},
    {provide: APP_VERSION, useValue: environment.config.version},
    {provide: DEBUG_MODE, useValue: isDevMode()},
    ScreenTrackingService,
    UserTrackingService,
  ] : []
  return [
    ...[
      provideFirebaseApp(() => {
        const fallback = {}
        const token = environment.config.firebase  //BrowserStorageService.getLocalConfig().firebase
        const app = initializeApp(token ?? fallback)
        log.debug(
          `${app ? '🙂' : '😩'}firebase init ${environment.useEmulators ? 'emulator ' : ''}${token ? 'token' : 'fallback'}`,
          token ?? fallback,
          app
        )
        //setLogLevel('debug')
        return app
      }),
      //provideAppCheck(() => initializeAppCheck(getApp(), {
      //  provider: new ReCaptchaEnterpriseProvider(_siteKey),
      //  isTokenAutoRefreshEnabled: true
      //})),
      //provideRemoteConfig(() => getRemoteConfig()),
      provideMessaging(() => getMessaging()),
      //provideServiceWorker('firebase-messaging-sw.js', {
      //  registrationStrategy: 'registerWhenStable:3000'
      //}),
      provideAuth(() => {
        const auth: Auth = initializeAuth(getApp(), {
          persistence: [
              indexedDBLocalPersistence,
              browserLocalPersistence,
              browserSessionPersistence
          ],
          popupRedirectResolver: browserPopupRedirectResolver
        })
        if (environment.useEmulators) {
          connectAuthEmulator(auth, 'http://localhost:9099', {
            disableWarnings: true
          })
        }
        return auth
      }),
      provideFirestore(() => {
        const firestore = initializeFirestore(getApp(), {
          localCache: persistentLocalCache({
            tabManager: persistentMultipleTabManager()
          }),
          ignoreUndefinedProperties: true,
          experimentalForceLongPolling: true
        })
        if (environment.useEmulators) {
          connectFirestoreEmulator(firestore, 'localhost', 8080)
        }
        return firestore
      }),
      //provideDatabase(() => {
      //  const database = getDatabase()
      //  if (environment.useEmulators) {
      //    connectDatabaseEmulator(database, 'localhost', 9000)
      //  }
      //  return database
      //}),
      provideStorage(() => {
        const storage = getStorage()
        if (environment.useEmulators) {
          connectStorageEmulator(storage, 'localhost', 9199)
        }
        return storage
      }),
      provideFunctions(() => {
        const functions = getFunctions()
        if (environment.useEmulators) {
          connectFunctionsEmulator(functions, 'localhost', 5001)
        }
        return functions
      }),
    ],
    ...productionProviders,
  ]
}

export const appConfig: ApplicationConfig = {
  providers: [
    //provideExperimentalZonelessChangeDetection(),
    provideZoneChangeDetection({ eventCoalescing: true, ignoreChangesOutsideZone: false }),
    provideClientHydration(withHttpTransferCacheOptions({includePostRequests: true})),
    provideRouter(
      appRoutes,
      //withDebugTracing(),
      withEnabledBlockingInitialNavigation(),
      withRouterConfig({ onSameUrlNavigation: 'reload' }),
      withComponentInputBinding(),
      withViewTransitions()
    ),
    provideAnimationsAsync(),
    provideHttpClient(withFetch()),
    importProvidersFrom(NgCircleProgressModule.forRoot()),
    provideServiceWorker('ngsw-worker.js', {
      enabled: !isDevMode(),
      registrationStrategy: 'registerImmediately'//'registerWhenStable:3000'
    }),
    provideFirebaseProviders(),
    {
      provide: 'sentryProviders',
      useFactory: provideSentryProviders,
      deps: [PLATFORM_ID]
    }
  ]
}
