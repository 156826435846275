/**
 * Configuration object for defining spacing constants used in gameplay styling.
 */
export const gameplaySpacing = {
  /** A non-breaking space character. */
  spacer: '&nbsp;',
  /** A small non-breaking space character. */
  smallSpacer: '&hairsp;',
} as const;

/**
 * Base styling constants used in gameplay elements.
 */
export const gameplayBaseStyling = {
  /** Font size style for comments. */
  commentStyle: 'font-size:clamp(0.8rem, 2.5vw, 1.1rem);',
  /** Ellipsis string with small spacers. */
  ellipsis: gameplaySpacing.smallSpacer + gameplaySpacing.spacer.repeat(3) + gameplaySpacing.smallSpacer,
  /** Ellipsis string with smaller spacers. */
  smallEllipsis: gameplaySpacing.smallSpacer + gameplaySpacing.smallSpacer.repeat(9) + gameplaySpacing.smallSpacer,
  /** Styling for the ellipsis element. */
  ellipsisStyle: 'text-decoration-line:underline; text-decoration-style:dotted; text-decoration-color:orange; text-underline-position:under;',
  /** Styling for wrong answers. */
  answerWrongStyle: 'color:red;',
  /** Styling for correct answers. */
  answerRightStyle: 'color:limegreen;',
} as const;

/**
 * Styling constants used in gameplay elements.
 */
export const gameplayStyling = {
  /** Base styling for gameplay elements. */
  baseStyle: gameplayBaseStyling.answerRightStyle,
  /** Styling for answers. */
  answerStyle: gameplayBaseStyling.answerRightStyle + ' ' + gameplayBaseStyling.ellipsisStyle,
  /** Styling for questions. */
  questionStyle: gameplayBaseStyling.ellipsisStyle,
  /** HTML representation of the ellipsis string with styling. */
  ellipsisHTML: '<strong style="' + gameplayBaseStyling.answerRightStyle + gameplayBaseStyling.ellipsisStyle+'">'+gameplayBaseStyling.ellipsis+'</strong>',
  /** Font family style for Pinyin text. */
  pinyin: 'font-family:source-han-sans-traditional;',
} as const;

/**
 * Regular expressions and patterns used for text replacement in gameplay elements.
 */
export const gameplayReplace = {
  /** Regular expression for matching tag elements. */
  tag: /[<{]\w[>}]([^>}]+)[<{]\/\w[>}]/gimu,
  /** Regular expression for matching subscript and superscript elements. */
  subsup: /([_^][<{]?(&nbsp;)?(\d+)?[+-]?[>}]?)+/gm,
  /** Regular expression for matching subscript elements. */
  sub: /_[<{]?(\w+|&nbsp;)[>}]?/gm,
  /** Regular expression for matching superscript elements. */
  sup: /\^[<{]?(&nbsp;|[\w+-]+)[>}]?/gm,
  /** Regular expression for matching non-breaking space characters. */
  ell: /&nbsp;/gm,
  /** Regular expression for matching punctuation characters. */
  punctuation: /\W/gm,
  /** Regular expression for matching parentheses. */
  parentheses: /(\(.*\))/gm,
  /** Regular expression for matching semicolons. */
  semicolon: /(;)/gm,
  /** Regular expression for cleanup after substituting subscript and superscript elements. */
  cleanup: /_|\^/gm,
  /** Regular expression for matching answer styling. */
  answerStyle: new RegExp(gameplayBaseStyling.answerRightStyle+'|'+gameplayBaseStyling.answerWrongStyle, 'gm'),
  /** Regular expression for matching spacers. */
  spacers: /&(nbsp|hairsp);/gm,
  /** Regular expression for matching small spacers. */
  smallSpacers: /&(hairsp);/gm,
} as const;

/**
 * Checks if a string contains Mandarin Chinese characters.
 * @param s The string to check.
 * @returns True if the string contains Mandarin Chinese characters, otherwise false.
 */
export const isMandarin = (s: string) => {
  const REGEX_CHINESE = /[\u4e00-\u9fff]|[\u3400-\u4dbf]|[\u{20000}-\u{2a6df}]|[\u{2a700}-\u{2b73f}]|[\u{2b740}-\u{2b81f}]|[\u{2b820}-\u{2ceaf}]|[\uf900-\ufaff]|[\u3300-\u33ff]|[\ufe30-\ufe4f]|[\uf900-\ufaff]|[\u{2f800}-\u{2fa1f}]/u;
  return REGEX_CHINESE.test(s);
};

/**
 * Converts attributed text into HTML span elements with specified class and style attributes.
 * @param text The text to convert.
 * @param classTag Additional class attribute for the span element (optional).
 * @param styleTag Additional style attribute for the span element (optional).
 * @param pinyin Font family style for Pinyin text (optional).
 * @returns HTML representation of the attributed text.
 */
export const convertAttributedText = ({
  text,
  classTag = '',
  styleTag = '',
  pinyin = gameplayStyling.pinyin,
}: {
  text: string;
  classTag?: string;
  styleTag?: string;
  pinyin?: string;
}) => {
  if (!text) {
    return '';
  }
  if (isMandarin(text)) {
    classTag += ' zh';
  }
  if (classTag.startsWith('pronunciation zh_pinyin')) {
    styleTag += pinyin;
  }
  styleTag = styleTag.length > 0 ? 'style="' + styleTag + '"' : '';
  classTag = classTag.length > 0 ? 'class="' + classTag + '"' : '';
  return '<span ' + classTag + styleTag + '>' + text + '</span>';
};
