export const environment = {
  production: true,
  useEmulators: false,
  api: {
    port:3000,
    url:'/api',
    baseUrl: '',
  },
  config: {} as any
}
