import { inject, Injectable } from "@angular/core"

import { Observable, of } from "rxjs"

import { EventParams, getAnalytics, logEvent, provideAnalytics, setUserProperties } from "@angular/fire/analytics"

import { environment } from "@nx-superprep/environment"

import { concatCurrentPath, GroupTemplate, LibraryNavigation, User } from "../models"

@Injectable({ providedIn: 'root' })
export abstract class AnalyticsService {

  private _analytics = inject(provideAnalytics, { optional: true })

  private get analytics() {
    if (!this._analytics) { this._analytics = getAnalytics() }
    return this._analytics
  }
  private set analytics(value) {
    this._analytics = value || getAnalytics()
  }

  onUserChange(user: User | undefined): Observable<User | undefined> {
    setUserProperties(this.analytics, {app_name: environment.config.app_name, app_version:environment.config.version})
    if (user) { if(user.isSignup) { this.logSignup() } else { this.logLogin() } }
    return of(user as User | undefined)
  }

  // analytics

  logEvent(name: string, params: EventParams = {}) {
    logEvent(this.analytics, name, {app_name:environment.config.name, app_version:environment.config.version, ...params})
  }

  logPerformanceFilterEvent(filter: GroupTemplate, state: LibraryNavigation | undefined) {
    const event = {
      content_type: filter.title,
      item_id: filter.id,
      location: concatCurrentPath(state)
    }
    this.logEvent('select_content', event)
  }

  logPerformanceEvent(value: string|undefined) {
    if (!value) { return }
    const event = {
      content_type: 'performance',
      item_id: value,
      location: `/performance/${value}`
    }
    this.logEvent('select_item', event)
  }

  logLibraryFilterEvent(filter: GroupTemplate, state: LibraryNavigation | undefined) {
    const event = {
      content_type: filter.title,
      item_id: filter.id,
      location: concatCurrentPath(state)
    }
    this.logEvent('select_content', event)
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  logLibraryEvent(value: any | undefined) {
    const section: string = value?.selection?.section?.title ?? value?.selection?.title ?? 'library'
    const selection: string = value?.selection?.item?.id ?? value?.selection?.item?.itemId
    const detail: string = value?.selection?.detail?.id
    const _itemId = detail ?? selection

    const event = _itemId ? {
      item_list_name: section,
      item_list_id: selection,
      items: [{item_id: _itemId, item_name: _itemId}],
      location: concatCurrentPath(value)
    } : {
      content_type: section ? section.toLowerCase() : 'library',
      item_id: section,
      location: concatCurrentPath(value)
    }

    if (event.content_type === 'library') { return }
    this.logEvent(_itemId ? 'select_item' : 'select_content', event)
  }

  logAppUpdate() {
    this.logEvent('app_update')
  }

  logAppOpen(component: string) {
    this.logEvent('app_open', {component})
  }

  logPageView(title: string, location?: string, path?: string) {
    this.logEvent('page_view', {page_title: title, page_location: location, page_path: path})
  }

  logSelectContent(event: EventParams): void {
    this.logEvent('select_content', event)
  }

  logSelectItem(event: EventParams): void {
    this.logEvent('select_item', event)
  }

  // custom events

  logSignup(eventParams?: EventParams) {
    logEvent(this.analytics, 'pstudy_sign_up', eventParams)
  }

  logLogin(eventParams?: EventParams) {
    logEvent(this.analytics, 'pstudy_login', eventParams)
  }

  // Onboarding

  logTutorialBegin() {
    this.logEvent('pstudy_tutorial_begin')
  }

  logTutorialComplete() {
    this.logEvent('pstudy_tutorial_complete')
  }

  // Lesson plan

  logDailyPlanStart(event: EventParams) {
    this.logEvent('pstudy_daily_lesson_plan_start', event)
  }

  logDailyPlanCompleted(event: EventParams) {
    this.logEvent('pstudy_daily_lesson_plan_completed', event)
  }

  logDailyLessonStart(event: EventParams) {
    this.logEvent('pstudy_daily_lesson_start', event)
  }

  logDailyLessonCompleted(event: EventParams) {
    this.logEvent('pstudy_daily_lesson_completed', event)
  }

  // Study session

  logSessionStart(event: EventParams) {
    this.logEvent('pstudy_study_session_start', event)
  }

  logSessionCompleted(event: EventParams) {
    this.logEvent('pstudy_study_session_completed', event)
  }

  // Content

  logLessonAdded(event: EventParams) {
    this.logEvent('pstudy_lesson_added', event)
  }

  logLessonRemoved(event: EventParams) {
    this.logEvent('pstudy_lesson_removed', event)
  }

  logLessonTryout(event: EventParams) {
    this.logEvent('pstudy_lesson_try_out', event)
  }

  logLessonStart(event: EventParams) {
    this.logEvent('pstudy_lesson_start', event)
  }

  logLessonCompleted(event: EventParams) {
    this.logEvent('pstudy_lesson_completed', event)
  }

  // Others

  logAccountDeletion(event: EventParams) {
    this.logEvent('pstudy_account_deletion', event)
  }

  logSettingEvent(lesson_id: string|undefined, change: object = {}): void {
    if (Object.keys(change).length === 0) { return }
    const key = Object.keys(change).find(() => true)
    if (!key) { return}
    const event = {
      lesson_id: lesson_id || 'N/A',
      setting_option_change: Object(change)[key]
    }
    this.logEvent(`pstudy_settings_change_${key}`, event)
  }

  logSearch(keyword: string) {
    this.logEvent('pstudy_search', {search_term: keyword})
  }

}
