@if(type === 'header') {
  <ng-template [ngTemplateOutlet]="_headerTemplate"></ng-template>
}
@else {
  <ng-template [ngTemplateOutlet]="_coverTemplate"></ng-template>
}

<ng-template #_headerTemplate>
  <mat-card class="header-card" appearance="outlined" [style.background-color]="backgroundColor">
    <mat-icon class="header-icon">check_circle</mat-icon>
    <mat-card-header>
      <mat-icon class="header-icon">check_circle</mat-icon>
      <mat-card-title-group>
        <mat-card-title>{{title}}</mat-card-title>
        <mat-card-subtitle>{{subTitle}}</mat-card-subtitle>
      </mat-card-title-group>
    </mat-card-header>
  </mat-card>
</ng-template>
<ng-template #_coverTemplate>
  <div class="card rounded-borders shadow mt-md-5 ms-md-5">
    <div class="col-fluid">
      <div class="row align-items-center">
        <div class="col-auto pe-0">
          <img loading="lazy" class="card-img" [src]="imageUrl | async" alt="{{ imageUrl }}" >
        </div>
        <div class="col ps-0 ms-0">
          <div class="card-body py-0 ps-1 ps-lg-2">
            <div class="card-text text-dark">
              <h5 class="card-text text-dark">{{ title }}</h5>
              <p class="card-text text-muted mt-1">{{ subTitle }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
